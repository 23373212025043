<template>
  <LoaderFullPage v-if="store.isLoading" />
  <form v-else :class="{ 'is-mobile': isMobile }" @submit.prevent="submit()">
    <h5>Inscription</h5>
    <FormInput
      v-model.trim="formData.first_name"
      label="Prénom"
      name="reg-firstname"
      type="text"
      :error="
        !isFormValid && !formData.first_name
          ? 'Veuillez renseigner votre prénom.'
          : null
      "
      required
    />
    <FormInput
      v-model.trim="formData.last_name"
      label="Nom"
      name="reg-lastname"
      type="text"
      :error="
        !isFormValid && !formData.last_name
          ? 'Veuillez renseigner votre nom.'
          : null
      "
      required
    />
    <FormInput
      v-model.trim="formData.email"
      label="Email professionnel"
      name="reg-email"
      type="email"
      :error="
        !isFormValid && !validateEmail(formData.email)
          ? 'Veuillez renseigner un email valide.'
          : null
      "
      required
    />
    <FormSelect
      v-model.trim="formData.occupation"
      label="Profession"
      name="Sélectionnez une profession"
      :items="store.occupations"
      :error="
        !isFormValid && !formData.occupation
          ? 'Veuillez renseigner votre profession.'
          : null
      "
      required
    />
    <FormTextarea
      v-model.trim="formData.organisation"
      label="Entreprise"
      name="reg-organisation"
      tip="(nom complet)"
      :error="
        !isFormValid && !formData.organisation
          ? 'Veuillez renseigner votre entreprise.'
          : null
      "
      required
    />
    <FormSelect
      v-model="formData.sectors"
      label="Secteur"
      name="Sélectionnez un secteur"
      :items="store.sectors"
      :error="
        !isFormValid && !formData.sectors
          ? 'Veuillez renseigner votre secteur.'
          : null
      "
      required
    />

    <CheckBox
      :checked="formData.conditions"
      name="reg-conditions"
      :error="
        !isFormValid && !formData.conditions
          ? 'Veuillez accepter les conditions d\'utilisation.'
          : null
      "
      @change.stop="formData.conditions = !formData.conditions"
    >
      <span>
        J'accepte les

        <BaseLink href="https://www.follaw.sv/conditions-generales-de-vente/">
          conditions générales d'utilisation et les conditions générales de
          vente
        </BaseLink></span
      >
    </CheckBox>
    <CheckBox
      :checked="formData.newsletter"
      name="reg-newsletter"
      @change.stop="formData.newsletter = !formData.newsletter"
    >
      <span
        >J'aimerais m'inscrire à
        <BaseLink href="https://mailchi.mp/saper-vedere/ijuklebci2">
          la newsletter
        </BaseLink></span
      >
    </CheckBox>
    <button type="submit" class="btn-primary" :class="{ 'btn-big': isMobile }">
      Commencer mon essai gratuit
    </button>
  </form>
</template>

<script setup>
import { ref } from "vue";
import { validateEmail } from "@/utils/helper.utils";
import LoaderFullPage from "@/components/reusable/molecules/LoaderFullPage.vue";
import FormInput from "@/components/reusable/atoms/FormInput.vue";
import FormTextarea from "@/components/reusable/atoms/FormTextarea.vue";
import FormSelect from "@/components/reusable/molecules/FormSelect.vue";
import CheckBox from "@/components/reusable/atoms/CheckBox.vue";
import { useRegisterStore } from "@/stores/register";
import BaseLink from "@/components/general/atoms/BaseLink.vue";

defineProps({
  isMobile: { type: Boolean, default: false },
});

const store = useRegisterStore();

const formData = ref({
  first_name: "",
  last_name: "",
  email: "",
  occupation: "",
  organisation: "",
  sectors: "",
  conditions: false,
  newsletter: false,
});

const isFormValid = ref(true);

const validate = () => {
  isFormValid.value =
    formData.value.first_name &&
    formData.value.last_name &&
    formData.value.email &&
    validateEmail(formData.value.email) &&
    formData.value.occupation &&
    formData.value.organisation &&
    formData.value.sectors &&
    formData.value.conditions;
};

const submit = () => {
  validate();
  if (isFormValid.value) {
    const dataToSubmit = { ...formData.value };
    delete dataToSubmit.conditions;

    store.registerUser(dataToSubmit);
  }
};

store.getRegisterOptions();
</script>

<style lang="scss" scoped>
form {
  display: flex;
  flex-direction: column;
  gap: 24px;

  h5 {
    text-align: center;
  }

  // This avoids the dropdown from getting out of the layout and extending the body with no background
  &.is-mobile :deep(.items-list) {
    max-height: 140px !important;
  }
}
</style>
